<template>
  <div class="border rounded py-2 h-full relative">
    <slot name="image">
      <div class="rounded flex justify-center items-center mx-auto px-1 h-52">
        <div class="w-auto max-h-52 rounded overflow-hidden h-full">
          <div class="inline-flex items-center justify-center m-2 relative overflow-hidden h-full">
            <img
              style="height: 90%; object-fit: contain;"
              :src="__thumbnail"
            >
            <div
              v-if="__wmEnabled"
              class="absolute inset-0 text-center"
            >
              <p
                v-for="p in 50"
                :key="p"
                class="my-px truncate whitespace-nowrap watermark"
              >
                <span
                  v-for="col in 3"
                  :key="col"
                  class="px-2"
                >
                  {{ currentUser.name }} - {{ formatDate(Date(), 'MM/DD/YYYY HH:MM') }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </slot>
    <div
      v-if="title"
      class="text-center font-normal text-sm my-2 break-all px-2 truncate"
    >
      {{ title }}
    </div>
    <slot name="cardAdditionalContent" />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { formatDate } from '@/helpers/util';

export default {
    name: 'StyleGuideAssetCard',

    props: {
        srcAsset: {
            type: Object,
            default: {},
            description: 'source asset'
        },
        imageAlt: {
            type: String,
            default: '',
            description: 'image alt text'
        },
        title: {
            type: String,
            default: '',
            description: 'text on card placed below image'
        }
    },

    setup (props) {
        const store = useStore();
        const currentUser = computed(() => store.getters['auth/getUserInfo']);

        const __wmEnabled = ref(false)

        const __type = computed(() =>{
          if (props.srcAsset?.name && props.srcAsset.renditions?.original) {
            let ext = props.srcAsset?.name.toLowerCase().split('.').pop()
            if (ext) {
              // AUDIO
              if (['wav','mp3','aif','aiff'].includes(ext)) return 'audio'
              // VIDEO
              else if (['mov','mp4','m4v','avi'].includes(ext)) return 'video'
              // PDF
              else if (['pdf'].includes(ext)) return 'pdf'
              // ZIP
              else if (['zip'].includes(ext)) return 'zip'
              // IMAGE
              else return 'image'
            }
          }
          return 'other'
        })

        const __thumbnail = computed(() =>{
          let tmpThumbnail = require('@/assets/images/upload/asset.png');
          __wmEnabled.value = false

          if (props.srcAsset?.name && props.srcAsset.renditions?.original) {
            const __rends = props.srcAsset.renditions.original
            let ext = props.srcAsset?.name.toLowerCase().split('.').pop()
            if (ext) {

              // AUDIO
              if (['wav','mp3', 'aif', 'aiff'].includes(ext)) {
                tmpThumbnail = require('@/assets/images/upload/audio.png');
              }

              // VIDEO
              else if (['mov','mp4','m4v','avi'].includes(ext)) {
                tmpThumbnail = require('@/assets/images/upload/video.png');
                if (__rends.videoProxy){
                   tmpThumbnail = __rends.videoPreview
                   __wmEnabled.value = true
                }
              }

              // PDF
              else if (['pdf'].includes(ext)) {
                tmpThumbnail = require('@/assets/images/upload/pdf-small.png');
                if (__rends.imagePreview && !__rends.imagePreview.includes('/700x700')){
                   tmpThumbnail = __rends.imagePreview
                   __wmEnabled.value = true
                }
              }

              // IMAGE
              else {
                if (__rends.imagePreview && !__rends.imagePreview.includes('/700x700')){
                   tmpThumbnail = __rends.imagePreview
                   __wmEnabled.value = true
                }
              }

            }
          }

          return tmpThumbnail
        })


        return {
            currentUser,
            formatDate,
            __thumbnail,
            __type,
            __wmEnabled
        };
    }
};
</script>

<style>
.watermark {
  font-weight: 600;
  font-size: .8em;
  color: rgba(128,128,128,.45);
}
</style>
